import React from "react"
import NavigationBar from "../components/global/NavigationBar/NavigationBar"
import Layout from "../components/Layout/Layout"
import SiteFooter from "../components/global/SiteFooter/SiteFooter"
import Terms from "../components/Terms/Terms"
import { graphql, useStaticQuery } from "gatsby"

const PageNotFound = () => {
  const data = useStaticQuery(graphql`
    query termsPageQuery {
      prismicBlindcvTermsAndConditions {
        data {
          terms_page_analytics {
            document {
              ... on PrismicBlindcvPageAnalytics {
                id
                data {
                  event
                  page_title
                  page_type
                }
              }
            }
          }
          page_content {
            html
          }
          title
        }
      }
    }
  `)

  const termsPage = data.prismicBlindcvTermsAndConditions.data
  return (
    <div>
      <Layout pageViewAnalyticsData={
          data.prismicBlindcvTermsAndConditions.data.terms_page_analytics.document.data
        }>
        <NavigationBar />
        <div className="page-content">
          <Terms data={termsPage} />
          <SiteFooter />
        </div>
      </Layout>
    </div>
  )
}
export default PageNotFound
