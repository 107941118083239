import React from "react"
import { Markup } from "interweave"
import "./Terms.scss"

const Terms = (props) => {
  return (
    <section className="terms-page">
      <div className="container">
        <div className="row">
            <div>
              <h1>{props.data.title}</h1>
              <Markup content={props.data.page_content.html}></Markup>
            </div>
          </div>
      </div>
    </section>
  )
}
export default Terms;